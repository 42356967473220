import axios from 'axios';
import { base64toBlob } from '../@iosper/utils/Format';

export const axiosGet = async (url) => {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((result) => {
      //tener en cuenta que si el error es por acceso no autorizado, no mostramos error en la vista y tampoco entra al catch, ya que directamente lo mandamos al login
      return result?.data ? result.data : null;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const axiosPost = async (url, data) => {
  return axios
    .post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((result) => {
      //tener en cuenta que si el error es por acceso no autorizado, no mostramos error en la vista y tampoco entra al catch, ya que directamente lo mandamos al login
      return result?.data ? result.data : null;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

// ------- imprimir archivos
export const axiosPostFile = async (url, data) => {
  return axios
    .post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'arraybuffer',
        Accept: 'application/pdf',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((result) => {
      //Create a Blob from the PDF Stream
      const file = base64toBlob(result.data, 'application/pdf');
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const axiosGetFile = async (url) => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    responseType: 'blob',
  };

  return axios
    .request(config)
    .then((response) => {
      /**
       * Se habilita la lectura desde php para 'Content-Disposition'
       * ->withHeader("Access-Control-Expose-Headers", 'Content-Disposition')
       */
      return {
        apiResponse: response.data,
        contentDisposition: response.headers['content-disposition'],
      };
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
