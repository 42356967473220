import { useEffect, useState } from 'react';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import { getFile } from '../../services/Archivos/Files';
import { Link, Typography } from '@mui/material';

const labelStyle = {
  display: 'flex',
  alignItems: 'center',
  display: 'block',
  fontSize: '13px',
  fontWeight: 500,
  gap: '5px',
};

export const PreviewFileField = ({ label, href, src }) => {
  const [downloadUrl, setDownloadUrl] = useState(null);

  useEffect(() => {
    if (downloadUrl?.contentUrl && downloadUrl?.filename) {
      const link = document.createElement('a');
      link.href = downloadUrl?.contentUrl;
      link.download = downloadUrl?.filename; // Usar el nombre del archivo extraído
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    return () => URL.revokeObjectURL(downloadUrl?.downloadUrl);
  }, [downloadUrl]);

  const descargarArchivo = async () => {
    if (!src) {
      return;
    }

    // 1. Obtener el archivo desde una llamada API
    const { apiResponse, contentDisposition } = await getFile(src);

    try {
      // 2. Obtener nombrede archivo desde Content-Disposition
      let filename = null;
      const filenameRegex = /filename=(.+)/; // Regex para filename*=UTF-8''filename.ext
      const filenameMatch = contentDisposition.match(filenameRegex);

      if (filenameMatch) {
        const decodedFilename = decodeURIComponent(filenameMatch[1].replaceAll('"', ''));
        filename = decodedFilename;
      } else {
        console.warn('No se pudo extraer el nombre del archivo.');
      }

      // 3. Crear URL para el Blob
      setDownloadUrl({ contentUrl: URL.createObjectURL(apiResponse), filename });
    } catch (error) {
      console.error('Error al procesar la respuesta de la API:', error);
    }
  };

  return (
    <>
      <Link
        href={href}
        underline='hover'
        sx={{ cursor: 'pointer' }}
        onClick={() => src && descargarArchivo()}
        download
      >
        <Typography variant='overline' sx={labelStyle} lineHeight='1.7'>
          {label}
          <AttachFileIcon fontSize='small' />
        </Typography>
      </Link>
    </>
  );
};
