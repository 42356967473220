import {
  Help as HelpIcon,
  Info as InfoIcon,
  Cancel as CancelIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { Grid } from '@mui/material';

const estadoSelectorConfig = ({ codigo, nroSolicitud, descripcionEstadoSolicitud }) => {
  let estado = {
    mensaje: `La Solicitud de Autorización N° <strong>${nroSolicitud}</strong> se encuentra <strong>En Revisión</strong>.  
    <br/>Por favor, verifique más tarde o contacte con la delegación de IOSPER más cercana.`,
    color: '#F5F5F5',
    textColor: '#757575',
    icon: InfoIcon,
    iconColor: '#9E9E9E',
  };

  switch (codigo) {
    case '1':
      estado = {
        mensaje: `La Solicitud de Autorización N° <strong>${nroSolicitud}</strong> ha sido <strong>${descripcionEstadoSolicitud}</strong>.`,
        color: '#E3F2FD', // #E3F2FD
        textColor: '#0D47A1', // #0D47A1
        icon: InfoIcon,
        iconColor: '#2196F3', // #2196F3
      };
      break;
    case '2':
      estado = {
        mensaje: `La Solicitud de Autorización N° <strong>${nroSolicitud}</strong> ha sido <strong>${descripcionEstadoSolicitud}</strong>. 
        <br/>Por favor, revise los motivos y considere iniciar una nueva solicitud si es necesario.`,
        color: '#FFEBEE', // #FFEBEE
        textColor: '#B71C1C', // #B71C1C
        icon: CancelIcon,
        iconColor: '#F44336', // #F44336
      };
      break;
    case '3':
      estado = {
        mensaje: `La Solicitud de Autorización N° <strong>${nroSolicitud}</strong> ha sido <strong>${descripcionEstadoSolicitud}</strong>. 
        <br/>Por favor, revise los motivos y considere iniciar una nueva solicitud si es necesario.`,
        color: '#FFF3E0', // #FFF3E0
        textColor: '#E65100', // #E65100
        icon: WarningIcon,
        iconColor: '#FF9800', // #FF9800
      };
      break;
    case '4':
      estado = {
        mensaje: `La Solicitud de Autorización N° <strong>${nroSolicitud}</strong> ha sido <strong>${descripcionEstadoSolicitud}</strong>.`,
        color: '#E8F5E9', // #E8F5E9
        textColor: '#1B5E20', // #1B5E20
        icon: CheckCircleIcon,
        iconColor: '#4CAF50', // #4CAF50
      };
      break;

    default:
      break;
  }

  return estado;
};

export const EstadoAutorizacion = ({ estadoSolicitud, ...props }) => {
  const config = estadoSelectorConfig({
    codigo: estadoSolicitud.codigoEstado || null,
    nroSolicitud: estadoSolicitud.idSolicitudCobertura,
    descripcionEstadoSolicitud: estadoSolicitud.descripcionEstadoSolicitudCobertura,
  });
  const IconComponent = config.icon;

  return (
    <div
      className='container'
      style={{
        backgroundColor: config.color,
        borderRadius: '10px',
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
        marginTop: props.marginTop || '0px',
        paddingBottom: '10px',
        marginBottom: '10px',
      }}
    >
      <IconComponent
        style={{
          color: config.iconColor,
          marginRight: '10px',
          fontSize: '24px',
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <p
            style={{ color: config.textColor, margin: 0 }}
            dangerouslySetInnerHTML={{ __html: config.mensaje }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
