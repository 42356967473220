import { VideoCall as VideoCallIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AfiliadoSelect from '../Afiliados/AfiliadoSelect';
import ButtonBack from '../Breadcrumb/ButtonBack';
import { useEffect, useState } from 'react';
import { CodeTypography } from '../Common/CodeTypography';
import { consultarCuposOrdenMedica } from '../../services/Seo/Orden';
import { getLinkTeleconsulta } from '../../services/Seo/Teleconsulta';

export const TeleconsultaAcceso = () => {
  const [afiliadoSelected, setAfiliadoSelected] = useState(null);
  const [cupoMensual, setCupoMensual] = useState(0);
  const [cupoAnual, setCupoAnual] = useState(0);
  const [mensajeCupo, setMensajeCupo] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (afiliadoSelected) {
      findCupoAfiliado(afiliadoSelected.afiliado);
    }
  }, [afiliadoSelected]);

  const onHandleAfiliadoSelected = (e) => {
    setLoading(true);
    setAfiliadoSelected(e);
    if (!e?.afiliado) return;
  };

  const onHandleSubmit = async () => {
    const link = await getLinkTeleconsulta(afiliadoSelected?.afiliado?.idAfiliado);
    if (link?.url) {
      window.open(link.url, '_blank');
    }
  };

  const findCupoAfiliado = async (afiliado) => {
    try {
      if (!afiliado) return;
      const response = await consultarCuposOrdenMedica({
        idAfiliado: afiliado.idAfiliado,
        idPractica: 525,
      });

      const cA =
        response?.consumoCupoOrdenMedicaVO[0]?.consumoCupo?.restantesConExtraordinarias || 0;
      const cM =
        response?.consumoCupoOrdenMedicaVO[1]?.consumoCupo?.restantesConExtraordinarias || 0;
      let message = '';

      if (cA > 0 && cM > 0) {
        const pluralAnual = cA === 1 ? '' : 's';
        const pluralMensual = cM === 1 ? '' : 's';
        message = (
          <>
            El afiliado tiene disponible{pluralAnual} {cA} consulta{pluralAnual} anuale
            {pluralAnual} <b>sin coseguro</b>, de la{pluralMensual} cuale{pluralMensual} {cM} puede
            utilizar este mes.
          </>
        );
      }

      if (cA > 0 && cM === 0) {
        message = (
          <>
            El afiliado NO posee cupo para <b>consultas sin coseguro</b> para este mes.
          </>
        );
      }

      if (cA === 0 && cM === 0) {
        message = (
          <>
            El afiliado NO posee cupo para <b>consultas sin coseguro</b> para este año.
          </>
        );
      }

      setCupoAnual(cA);
      setCupoMensual(cM);
      setMensajeCupo(message);
      setLoading(false);
    } catch (error) {
      setCupoAnual(0);
      setCupoMensual(0);
      setMensajeCupo('');
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ButtonBack />
        <Card sx={{ p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Teleconsulta
            </Typography>
            <Typography
              variant='body2'
              color='textSecondary'
              component='p'
              fontWeight='bold'
              gutterBottom
            >
              En esta sección podrás solicitar turno y obtener atención médica por teleconsulta.
            </Typography>
            <Alert severity='info' sx={{ mt: 2 }}>
              <Typography variant='body2' color='textSecondary' component='p' gutterBottom>
                La teleconsulta es un sistema de{' '}
                <CodeTypography>videoconferencia médica sin coseguro</CodeTypography>, que se
                desarrolla a través del uso de canales tecnológicos (computadora, celular o tablet){' '}
                <b>permitiendo la atención médica a distancia</b>, complementando la consulta médica
                tradicional.
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p' gutterBottom>
                A través de la videoconsulta podrás:{' '}
                <b>ver al médico, intercambiar mensajes, fotos, videos, audios y estudios</b>.
              </Typography>
            </Alert>
          </CardContent>
          <CardContent>
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <AfiliadoSelect onSelectAfiliado={(e) => onHandleAfiliadoSelected(e)} />
            </FormControl>
            {afiliadoSelected && loading && (
              <Grid item xs={12}>
                <Skeleton variant='rounded' width={'100%'} sx={{ mt: 1, height: '48.02px' }} />
              </Grid>
            )}
            {afiliadoSelected && !loading && (
              <Grid item xs={12}>
                <Alert
                  severity={cupoAnual > 0 && cupoMensual > 0 ? 'success' : 'error'}
                  sx={{ mt: 1 }}
                >
                  {mensajeCupo}
                </Alert>
              </Grid>
            )}
          </CardContent>
          <CardActions sx={{ p: 2 }}>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 2,
                  }}
                >
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<VideoCallIcon />}
                    disabled={loading || cupoMensual === 0}
                    onClick={(e) => onHandleSubmit()}
                  >
                    Iniciar Videoconsulta
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
