export const CodeTypography = ({ children, ...props }) => {
  return (
    <code
      {...props}
      style={{
        padding: '2px 4px',
        color: 'var(--muidocs-palette-text-primary, hsl(215, 15%, 12%))',
        backgroundColor: 'var(--muidocs-palette-grey-50, hsl(215, 15%, 97%))',
        border: '1px solid',
        borderColor: 'var(--muidocs-palette-grey-200, hsl(215, 15%, 89%))',
        borderRadius: '6px',
        fontSize: '0.8125rem',
        direction: 'ltr',
        WebkitBoxDecorationBreak: 'clone',
        boxDecorationBreak: 'clone',
      }}
    >
      {children}
    </code>
  );
};
