import { Box } from '@mui/material';

const containerStyle = {
  borderBottom: '1px dotted rgba(0, 0, 0, 0.22)',
  background: 'rgba(0, 0, 0, 0.01)',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  width: '100%',
  display: 'flex',
  padding: '10px 12px',
  alignItems: 'center',
  gap: '16px',
};

export const PreviewFieldContainer = ({ children, ...props }) => {
  return (
    <Box sx={containerStyle} {...props}>
      {children}
    </Box>
  );
};
